import { useCallback, useEffect } from 'react';

import { formatEther } from '@ethersproject/units';
import {
  TOKENTYPE,
  getETHNodeOperatorBond,
  getETHNodeOperatorCount,
  getETHRatedNetworkOverview,
  getETHSafeMode,
  getTokenPrices, // useTokenBalance,
  useAPY,
  useAllowance,
  useExchangeRate,
  useGasFees,
  useLimits,
  usePenalty,
  useTVL,
} from '@stader-labs/web-sdk';
import { usePathname } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';

import { useChainData } from '@/hooks/useChainData';
import { updateStakeData, updateUserData } from '@/store';
import { getCurrentChain } from '@/utils/common';

import {
  NON_APPROVAL_CHAINS_STAKE,
  NON_APPROVAL_CHAINS_UNSTAKE,
} from '../constants/common';
import { ETH, SD } from '../constants/constants';
import { useWithdrawals } from '../hooks/useWithdrawals';
import { formatEtherNumber } from '../utils/common';
import { sentryErrorLog } from '../utils/sentryLog';

const WebSDKServices = () => {
  const pathname = usePathname();
  const TOKEN = getCurrentChain(pathname);

  const dispatch = useDispatch();
  const { chainId } = useChainData();
  const {
    isConnected,
    walletAddress: address,
    config,
  } = useSelector((state: any) => state.wallet);
  const { approveTokenLoading, approveTokenXLoading, unstakeAmount } =
    useSelector((state: any) => state.stake);
  const { txn } = useSelector((state: any) => state.user);

  const gasFeesData = useGasFees();

  const { fetchAllowance } = useAllowance();
  const { fetchPenalty } = usePenalty({ enabled: true });
  const { fetchMinMaxLimit } = useLimits();

  const tvlData = useTVL({});
  const apyData = useAPY({});
  const conversionData = useExchangeRate({
    // @ts-expect-error - `TOKEN` does not have `SD`
    enabled: TOKEN !== SD,
  });

  useWithdrawals();

  const fetchPrices = useCallback(async () => {
    try {
      const data: any = await getTokenPrices(['ethereum', 'stader']);
      dispatch(updateStakeData({ prices: data }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('fetchPrices err', err);
    }
  }, [dispatch]);

  const fetchETHData = useCallback(async () => {
    try {
      const [
        safeMode,
        nodeOperatorBond,
        nodeOperatorCount,
        ratedNetworkOverview,
      ]: any = await Promise.all([
        getETHSafeMode(),
        getETHNodeOperatorBond(),
        getETHNodeOperatorCount(),
        getETHRatedNetworkOverview(),
      ]);
      dispatch(updateUserData({ isSafeMode: safeMode.value }));
      dispatch(
        updateStakeData({
          nodeOperatorsBond: { data: nodeOperatorBond, isLoading: false },
          nodeOperatorsCount: { data: nodeOperatorCount, isLoading: false },
          ratedNetworkOverview: {
            data: ratedNetworkOverview,
            isLoading: false,
          },
        }),
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('fetEthData err', err);
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      TOKEN === ETH ||
      // @ts-expect-error - `TOKEN` does not have `SD`
      TOKEN === SD
    ) {
      fetchETHData();
    }
    fetchPrices();
  }, [fetchETHData, fetchPrices]);

  const fetchLimitsData = async () => {
    try {
      const data: any = await fetchMinMaxLimit();
      dispatch(updateStakeData({ limits: data }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('fetchLimitsData err', err);
    }
  };

  useEffect(() => {
    if (config) {
      fetchLimitsData();
    }
  }, [address, config, tvlData]);

  useEffect(() => {
    if (tvlData && apyData) {
      dispatch(
        updateUserData({
          tvlData,
          apyData,
        }),
      );
    }

    if (conversionData) {
      dispatch(
        updateUserData({
          conversionData,
        }),
      );
    }
  }, [tvlData, apyData, conversionData]);

  const getAllowances = async () => {
    if (address) {
      const erc20Allowance = await fetchAllowance(address, TOKENTYPE.TOKEN);
      const xTokenAllowance = await fetchAllowance(address, TOKENTYPE.TOKENX);

      dispatch(
        updateStakeData({
          tokenAllowence: erc20Allowance?.hex
            ? formatEtherNumber(erc20Allowance.hex, 10)
            : null,
          tokenXAllowence: xTokenAllowance?.hex
            ? formatEtherNumber(xTokenAllowance.hex, 10)
            : null,
        }),
      );
    } else {
      dispatch(
        updateStakeData({
          tokenAllowence: null,
          tokenXAllowence: null,
        }),
      );
    }
  };

  useEffect(() => {
    if (
      isConnected &&
      ![...NON_APPROVAL_CHAINS_STAKE, ...NON_APPROVAL_CHAINS_UNSTAKE].includes(
        TOKEN,
      )
    ) {
      getAllowances();
      return;
    }
    dispatch(
      updateStakeData({
        tokenAllowence: null,
        tokenXAllowence: null,
      }),
    );
  }, [
    isConnected,
    approveTokenLoading,
    approveTokenXLoading,
    txn.status,
    address,
    TOKEN,
    chainId,
  ]);

  useEffect(() => {
    if (gasFeesData) {
      dispatch(updateStakeData({ gasFeesData }));
    }
  }, [gasFeesData]);

  const getPenalty = useCallback(
    (amt: string) => {
      dispatch(
        updateStakeData({
          isLoadingPenalty: true,
        }),
      );
      fetchPenalty(amt)
        .then((res: string) => {
          dispatch(
            updateStakeData({
              penalty: Number(formatEther(res)),
            }),
          );
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          console.log('Error Fetching Penalty', err);
          sentryErrorLog(err);
        })
        .finally(() => {
          dispatch(
            updateStakeData({
              isLoadingPenalty: false,
            }),
          );
        });
    },
    [fetchPenalty],
  );

  useEffect(() => {
    if (unstakeAmount) {
      getPenalty(unstakeAmount);
    }
  }, [unstakeAmount]);

  return null;
};

export default WebSDKServices;
