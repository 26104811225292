import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppTransactionType } from '@/dapp-sdk-v2/constants/events';

const token = process.env.NEXT_PUBLIC_TOKEN || '';

export interface UserStateProps {
  tokenAmount: number;
  tokenXAmount: number;
  tokenSymbol: string;
  tokenXSymbol: string;
  isNativeChain: boolean;
  chainID: number;
  tvlData: any;
  apyData: any;
  conversionData: any;
  isEmbed: boolean;
  isSafeApp: boolean;
  approvedTokenX: boolean;
  theme: string;
  txn: {
    original: any;
    hash: string;
    status: string;
    amount: string | null;
    token: string;
    type: string;
  };
  isSafeMode: boolean;
  isBannerVisible: boolean;
  isRestakeBannerVisible: boolean;
  lastTransaction: {
    type: AppTransactionType | null;
    amount: string | null;
  };
}

export const initialUserState: UserStateProps = {
  tokenAmount: 0,
  tokenXAmount: 0,
  tokenSymbol: '',
  tokenXSymbol: '',
  isNativeChain: true,
  chainID: 0,
  tvlData: null,
  apyData: null,
  conversionData: null,
  isEmbed: false,
  isSafeApp: false,
  approvedTokenX: false,
  theme: 'light',
  txn: {
    original: undefined,
    hash: '',
    status: '',
    amount: null,
    token: '',
    type: '',
  },
  isSafeMode: false,
  isBannerVisible: false,
  isRestakeBannerVisible: token === 'ETH',
  lastTransaction: {
    type: null,
    amount: null,
  },
};

const EXCEPTION_KEYS = ['isBannerVisible', 'isRestakeBannerVisible'];

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateUserData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearUserData: (state: any) => {
      for (const [key, value] of Object.entries(initialUserState)) {
        if (!EXCEPTION_KEYS.includes(key)) state[key] = value;
      }
    },
    resetTxnData: (state: any) => {
      state.txn = initialUserState.txn;
    },
    updateTxnData: (state: any, action: any) => {
      state.txn = action.payload.txn;
    },
    updateTransactionStatus: (state: any, action: any) => {
      state.txn.status = action.payload.status;
    },
    updateTxnHash: (state: any, action: any) => {
      state.txn.hash = action.payload.hash;
    },
    setLastTransaction: (
      state: UserStateProps,
      action: PayloadAction<{
        type: AppTransactionType | null;
        amount: string | null;
      }>,
    ) => {
      const type = action.payload.type ?? null;
      const amount =
        action.payload.amount !== ''
          ? action.payload.amount
          : state.lastTransaction.amount;

      state.lastTransaction = {
        type,
        amount,
      };
    },
  },
});

// TODO: remove `any` type and let TypeScript properly infer the type
export const {
  updateUserData,
  clearUserData,
  resetTxnData,
  updateTxnData,
  updateTransactionStatus,
  updateTxnHash,
}: any = userSlice.actions;

export const { setLastTransaction } = userSlice.actions;

export default userSlice.reducer;
